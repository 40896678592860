<template>
  <div class="pad-child">
    <div>
      <h2>{{ getText("404") }}</h2>
      <!-- <p>Click the button below to return to the previous page.</p> -->
      <a class="dex-button box outline" href="javascript:history.back()"><i class="dexcon-chevron-left-thin"></i><span>{{ getText("back") }}</span></a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("lang", {
      getText: "getText"
    }),
    location: function() {
      return window.location;
    }
  }
}
</script>