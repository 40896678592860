import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/dexcon.css'
import './assets/css/dex.css'
import axios from 'axios'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: function(h) { return h(App) }
}).$mount('#app')

axios.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem("kc_token")}`;
    return config;
});